import * as React from 'react';
import { useQuery } from '@apollo/client';
import Flickity from 'react-flickity-component';
import { Container } from '../../layout/Container';
import { Heading } from '../../ui/text/Heading';
import { Section } from '../../layout/Section';
import {
  useTableSort,
  IUseTableSort,
  IColumn
} from '../../../utils/hooks/useTableSort';
import styled from 'styled-components';
import { currency } from '../../../utils/format/currency';
import { useFadeTransition } from '../../../utils/hooks/useFadeTransition';
import { PROJECT_PRICELIST_QUERY } from '../../../../client/__graphql__/cluster/queries/estate/getProjectPricelist';
import { ENV_CONFIG } from '../../../__config__/env';
import {
  GetProjectPricelistQuery,
  GetProjectPricelistQueryVariables,
  Parking,
  ProjectUnitStatus,
  GetEstateIdQuery,
  GetEstateIdQueryVariables
} from '../../../__types__/generated/cluster';
import { client } from '../../../__graphql__/clientCluster';
import { ESTATE_ID_QUERY } from '../../../../client/__graphql__/cluster/queries/estate/getEstateId';
import { useLocation } from 'react-router';

const Prisliste = (props: { match: any; history: any }) => {
  const [openRow, setOpenRow] = React.useState<number>(-1);
  const location = useLocation();
  const preview = /\/preview$/i.test(location?.pathname);

  const { fadeIn } = useFadeTransition({
    immidiate: true
  });

  const { data: estateIdData } = useQuery<
    GetEstateIdQuery,
    GetEstateIdQueryVariables
  >(ESTATE_ID_QUERY, {
    client,
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        assignmentNumber: props.match.params.id,
        brandId: ENV_CONFIG.BRAND_ID
      }
    }
  });

  const { data, error, loading } = useQuery<
    GetProjectPricelistQuery,
    GetProjectPricelistQueryVariables
  >(PROJECT_PRICELIST_QUERY, {
    client,
    skip: !estateIdData?.getEstateId,
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        brandId: ENV_CONFIG.BRAND_ID,
        estateId: estateIdData?.getEstateId,
        preview: preview
      },
      project: {
        pagination: {
          limit: 200,
          page: 0
        }
      }
    }
  });

  const { columns, setColumns, getSorted, handleColumnClick } = useTableSort({
    columns: []
  } as IUseTableSort);
  const hasBRAi = data?.estate.project?.units?.some(
    (unit) => unit?.size?.grossInternalArea ?? 0 > 0
  );
  const hasProm = data?.estate.project?.units?.some(
    (unit) => unit?.size?.primaryArea ?? 0 > 0
  );

  // Hack to conditionally include BRA-i or P-rom based on BRA-i presence, remove when p-rom when no longer in use
  React.useEffect(() => {
    const updatedColumns: IColumn[] = [
      {
        id: 'unit',
        label: 'Enhetsnr.',
        sortable: true,
        active: false,
        sorting: 'ASC'
      },
      { id: 'floor', label: 'Plan', sortable: true },
      { id: 'bedrooms', label: 'Sov', sortable: true },
      { id: 'bra', label: 'BRA', sortable: true },
      //show P-ROM if we only have that one
      {
        id: 'computedArea',
        label: !hasBRAi && hasProm ? 'P-rom' : 'BRA-i',
        sortable: true
      },
      { id: 'plot', label: 'Tomt', sortable: true },
      { id: 'price', label: 'Pris', sortable: true },
      { id: 'commonShareDebt', label: 'Fellesgjeld', sortable: true },
      { id: 'totalPrice', label: 'Totalpris', sortable: true },
      { id: 'type', label: 'Type', sortable: true },
      { id: 'parking', label: 'Parkering', sortable: true },
      { id: 'sold', label: 'Status', sortable: true }
    ];

    setColumns(updatedColumns);
  }, [data?.estate]);

  if (error) {
    console.log(error);
    return null;
  }
  if (loading) {
    return null;
  }
  if (!data) {
    return null;
  }

  let units = data?.estate?.project?.units?.length
    ? data?.estate?.project?.units
        .filter(
          (unit) =>
            (!preview ? unit?.published : true) ||
            unit?.status === ProjectUnitStatus.Reserved ||
            unit?.sold
        )
        .map((unit, index) => {
          return {
            id: unit?.assignmentNumber,
            unit: unit?.apartmentNumber,
            floor: Number(unit?.floor),
            bedrooms: Number(unit?.rooms?.bedrooms),
            rooms: Number(unit?.rooms?.total),
            bra: Number(unit?.size?.usableArea),
            computedArea: Number(
              hasBRAi ? unit?.size?.grossInternalArea : unit?.size?.primaryArea
            ),
            plot: unit?.plot?.size,
            price: !unit?.sold ? Number(unit?.prices?.askingPrice) : 0,
            commonShareDebt: !unit?.sold
              ? Number(unit?.debt?.collectiveDebt)
              : 0,
            totalPrice: !unit?.sold ? Number(unit?.prices?.totalPrice) : 0,
            sold: unit?.sold,
            type: unit?.type,
            floorplan: unit?.images?.floorplans,
            bidurl: unit?.referenceUrls?.biddingUrl,
            reserved: (unit?.status as ProjectUnitStatus) === 'Reserved',
            parking: unit?.parking,
            index
          };
        })
    : [];

  const toggleOpen = (e: any) => {
    const id = parseInt(e.currentTarget.getAttribute('data-unit'));
    if (isNaN(id) || id === openRow) {
      return setOpenRow(-1);
    }
    setOpenRow(id);
  };

  const sortedInfo = getSorted()[0];
  const sortedUnits = units.sort((a: any, b: any) => {
    if (!sortedInfo) {
      // Dersom ingen sort er valgt - sorter på status, så unit
      const o1 = a.sold;
      const o2 = b.sold;

      const res1 = a.reserved;
      const res2 = b.reserved;

      const p1 = a.unit;
      const p2 = b.unit;

      if (o1 < o2) return -1;
      if (o1 > o2) return 1;
      if (res1 < res2) return -1;
      if (res1 > res2) return 1;
      if (p1 < p2) return -1;
      if (p1 > p2) return 1;
      return 0;
    }

    if (Array.isArray(a[sortedInfo.key])) {
      const lengthA = a[sortedInfo.key]?.length ?? 0;
      const lengthB = b[sortedInfo.key]?.length ?? 0;

      if (sortedInfo?.sort === 'asc') {
        return lengthA - lengthB;
      }
      if (sortedInfo?.sort === 'desc') {
        return lengthB - lengthA;
      }
    }

    if (sortedInfo && typeof a[sortedInfo.key] === 'boolean') {
      if (sortedInfo?.sort === 'asc') {
        return Number(a[sortedInfo.key]) - Number(b[sortedInfo.key]);
      }
      if (sortedInfo?.sort === 'desc') {
        return Number(b[sortedInfo.key]) - Number(a[sortedInfo.key]);
      }
      return 0;
    } else if (sortedInfo && typeof a[sortedInfo.key] === 'string') {
      if (sortedInfo?.sort === 'asc') {
        return a[sortedInfo.key].localeCompare(b[sortedInfo.key], undefined, {
          numeric: true
        });
      }
      if (sortedInfo?.sort === 'desc') {
        return b[sortedInfo.key].localeCompare(a[sortedInfo.key], undefined, {
          numeric: true
        });
      }
    } else {
      if (sortedInfo?.sort === 'asc') {
        return a[sortedInfo.key] - b[sortedInfo.key];
      }
      if (sortedInfo?.sort === 'desc') {
        return b[sortedInfo.key] - a[sortedInfo.key];
      }
      return 0;
    }
  });

  return (
    <Section style={fadeIn}>
      <Container>
        <Section>
          <Heading tag="h1" center={true}>
            {data?.estate?.streetAddress}
          </Heading>
          <Heading tag="h2" center={true}>
            Prisliste
          </Heading>
        </Section>
      </Container>
      <PropertyPricelist>
        <thead>
          <tr>
            {columns.map((item: any) => {
              return (
                <th
                  key={`table_head_${item.id}`}
                  style={item.style ? item.style : {}}
                  className={`${
                    item.active
                      ? 'clickable active'
                      : item.sortable === false
                      ? ''
                      : 'clickable'
                  } ${sortedInfo?.sort ? sortedInfo.sort : ''}`}
                  onClick={() => handleColumnClick(item.id)}
                >
                  <span>{item.label}</span>
                  {item.active && sortedInfo?.sort === 'asc' && (
                    <Arrow className="up" />
                  )}
                  {item.active && sortedInfo?.sort === 'desc' && (
                    <Arrow className="down" />
                  )}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {sortedUnits &&
            sortedUnits.map((unit, index: number) => {
              const parkingIsIncluded = unit?.parking?.find(
                (p: Parking) => p.included
              );
              return (
                <React.Fragment key={`unit${index}`}>
                  <tr onClick={toggleOpen} data-unit={unit.index}>
                    <td>{unit.unit}</td>
                    <td>{unit.floor}</td>
                    <td>{unit.bedrooms}</td>
                    <td>{Math.round(unit.bra)}m&sup2;</td>
                    <td>
                      {unit.computedArea
                        ? `${Math.round(unit.computedArea)}m²`
                        : '-'}
                    </td>
                    <td>{unit.plot ? `${Math.round(unit.plot)}m²` : '-'}</td>
                    <td>
                      {!unit.sold
                        ? currency({
                            number: unit.price,
                            seperator: ' '
                          }) + ',-'
                        : '-'}
                    </td>
                    <td>
                      {!unit.sold && unit.commonShareDebt
                        ? currency({
                            number: unit.commonShareDebt,
                            seperator: ' '
                          }) + ',-'
                        : '-'}
                    </td>
                    <td>
                      {!unit.sold
                        ? currency({
                            number: unit.totalPrice,
                            seperator: ' '
                          }) + ',-'
                        : '-'}
                    </td>
                    <td>{unit.type}</td>
                    <td>
                      {parkingIsIncluded || unit?.parking?.length === 0
                        ? unit?.parking?.length ?? 0
                        : `(${unit?.parking?.length ?? 0})`}
                    </td>
                    <td>
                      {unit.reserved ? (
                        <span style={{ color: 'red' }}>Reservert</span>
                      ) : (
                        <>
                          {unit.sold ? (
                            <span className="sold">Solgt</span>
                          ) : (
                            <span>
                              <a
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                                href={`${unit.bidurl}`}
                              >
                                Kjøp
                              </a>
                            </span>
                          )}
                        </>
                      )}
                    </td>
                  </tr>
                  {openRow > -1 && openRow === unit?.index && (
                    <tr>
                      <td colSpan={12}>
                        {unit?.floorplan && unit?.floorplan?.length > 0 && (
                          <FlickityWrap>
                            <Flickity
                              className={'carousel'}
                              elementType={'div'}
                              options={{}}
                            >
                              {unit?.floorplan?.map((floorplan, i: number) => {
                                return (
                                  <Cell key={floorplan.url}>
                                    <Floorplan
                                      src={floorplan.url}
                                      alt="bilde av plantegning"
                                    />
                                  </Cell>
                                );
                              })}
                            </Flickity>
                          </FlickityWrap>
                        )}
                        <ApartmentInfo>
                          <Status>
                            {unit.reserved ? (
                              <span
                                style={{
                                  color: 'red'
                                }}
                              >
                                Reservert
                              </span>
                            ) : (
                              <>
                                {unit.sold ? (
                                  <span
                                    style={{
                                      color: 'red'
                                    }}
                                  >
                                    Solgt
                                  </span>
                                ) : (
                                  <span
                                    style={{
                                      color: '#e8c893'
                                    }}
                                  >
                                    Ledig
                                  </span>
                                )}
                              </>
                            )}
                          </Status>
                          <Info>
                            <div className="unit">{unit.unit}</div>
                            <div>
                              {!hasBRAi && hasProm ? 'P-rom' : 'BRA-i'}{' '}
                              {unit.computedArea} m&sup2;
                            </div>
                            <div>Bruksareal {unit.bra} m&sup2;</div>
                            {unit.plot ? (
                              <div>Tomt {unit.plot} m&sup2;</div>
                            ) : null}
                            {unit.rooms ? (
                              <div>Antall rom {unit.rooms}</div>
                            ) : null}
                            {unit.bedrooms ? (
                              <div>Soverom {unit.bedrooms}</div>
                            ) : null}
                            {/*TODO: Handle scenarios where one parking is included and one is not*/}
                            {unit?.parking?.length &&
                            unit?.parking?.length > 0 ? (
                              <div>
                                Antall parkeringer {unit?.parking?.length}
                                {!parkingIsIncluded ? ' (tilvalg)' : ''}
                              </div>
                            ) : null}
                            {!unit.sold && (
                              <div>
                                Pris{' '}
                                {currency({
                                  number: unit.price,
                                  seperator: ' '
                                }) + ',-'}
                              </div>
                            )}
                            {!unit.sold && (
                              <div>
                                Totalpris{' '}
                                {currency({
                                  number: unit.totalPrice,
                                  seperator: ' '
                                }) + ',-'}
                              </div>
                            )}
                          </Info>
                        </ApartmentInfo>
                        {!unit.sold && !unit.reserved ? (
                          <MeldKjop>
                            <Button
                              href={`https://privatmegleren.no/registrerinteressent/${unit.id}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Meld interesse
                            </Button>
                            <Button
                              href={`${unit.bidurl}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Kjøp
                            </Button>
                          </MeldKjop>
                        ) : null}
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              );
            })}
        </tbody>
      </PropertyPricelist>
    </Section>
  );
};

export default Prisliste;

const PropertyPricelist = styled.table`
  color: #fff;
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;
  background-color: #111;
  thead {
    background-color: #000;
  }
  tr {
    width: 100%;
    cursor: pointer;
  }
  th {
    cursor: pointer;
    position: relative;
  }
  th,
  td {
    text-align: center;
    border-bottom: 1px solid #1c1c1c;
    padding: 10px 0;
    margin: 0;
  }
  td {
    // padding: 10px;
    color: #b5b5b5;
  }

  .sold {
    color: red;
  }

  .active {
    background-color: #e8c893;
    color: #000;
  }

  @media (max-width: 400px) {
    th:nth-child(2),
    td:nth-child(2),
    th:nth-child(3),
    td:nth-child(3),
    th:nth-child(4),
    td:nth-child(4),
    th:nth-child(6),
    td:nth-child(6),
    th:nth-child(7),
    td:nth-child(7),
    th:nth-child(8),
    td:nth-child(8),
    th:nth-child(10),
    td:nth-child(10),
    th:nth-child(11),
    td:nth-child(11) {
      display: none;
    }
  }
  @media (max-width: 800px) {
    th:nth-child(2),
    td:nth-child(2),
    th:nth-child(3),
    td:nth-child(3),
    th:nth-child(6),
    td:nth-child(6),
    th:nth-child(7),
    td:nth-child(7),
    th:nth-child(8),
    td:nth-child(8),
    th:nth-child(11),
    td:nth-child(11) {
      display: none;
    }
  }
`;

const Arrow = styled.div`
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 7px solid #000;
  position: relative;
  top: -1px;
  left: 6px;
  display: inline-block;
  &.up {
    border-top: none;
    border-bottom: 7px solid #000;
    top: -1px;
    left: 6px;
  }
`;

const FlickityWrap = styled.div`
  padding: 0 0 30px;
  .flickity-enabled {
    position: relative;
  }

  .flickity-enabled:focus {
    outline: none;
  }

  .flickity-viewport {
    overflow: hidden;
    position: relative;
    height: 100%;
  }

  .flickity-slider {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  /* draggable */

  .flickity-enabled.is-draggable {
    -webkit-tap-highlight-color: transparent;
    tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .flickity-enabled.is-draggable .flickity-viewport {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab;
  }

  .flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }

  /* ---- flickity-button ---- */

  .flickity-button {
    position: absolute;
    background: hsla(0, 0%, 100%, 0.75);
    border: none;
    color: #333;
  }

  .flickity-button:hover {
    background: white;
    cursor: pointer;
  }

  .flickity-button:focus {
    outline: none;
    box-shadow: 0 0 0 5px #19f;
  }

  .flickity-button:active {
    opacity: 0.6;
  }

  .flickity-button:disabled {
    opacity: 0.3;
    cursor: auto;
    /* prevent disabled button from capturing pointer up event. #716 */
    pointer-events: none;
  }

  .flickity-button-icon {
    fill: #333;
  }

  /* ---- previous/next buttons ---- */

  .flickity-prev-next-button {
    top: 50%;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    /* vertically center */
    transform: translateY(-50%);
  }

  .flickity-prev-next-button.previous {
    left: 10px;
  }
  .flickity-prev-next-button.next {
    right: 10px;
  }
  /* right to left */
  .flickity-rtl .flickity-prev-next-button.previous {
    left: auto;
    right: 10px;
  }
  .flickity-rtl .flickity-prev-next-button.next {
    right: auto;
    left: 10px;
  }

  .flickity-prev-next-button .flickity-button-icon {
    position: absolute;
    left: 20%;
    top: 20%;
    width: 60%;
    height: 60%;
  }

  /* ---- page dots ---- */

  .flickity-page-dots {
    position: absolute;
    width: 100%;
    bottom: -25px;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    line-height: 1;
  }

  .flickity-rtl .flickity-page-dots {
    direction: rtl;
  }

  .flickity-page-dots .dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 8px;
    background: #333;
    border-radius: 50%;
    opacity: 0.25;
    cursor: pointer;
    background: transparent;
    border: 2px solid white;
  }

  .flickity-page-dots .dot.is-selected {
    opacity: 1;
    background: white;
  }
`;

const Cell = styled.div`
  width: 100%;
  margin-right: 15px;
`;
const Floorplan = styled.img`
  max-width: 100%;
`;

const ApartmentInfo = styled.div`
  text-align: left;
  padding: 3em;
  color: #fff;
  line-height: 1.4;
  overflow: auto;

  .unit {
    font-size: 30px;
    text-align: left;
    margin: 0 0 20px;
    font-weight: 200;
    width: 100%;
    color: #e8c893;
    font-family: 'DomaineDisp';
  }
`;

const Status = styled.div`
  width: 200px;
  padding: 10px;
  background-color: #222;
  text-align: center;
  @media (min-width: 800px) {
    float: left;
  }
`;

const Info = styled.div`
  padding: 2em 0;
  &:after {
    clear: both;
  }

  @media (min-width: 800px) {
    float: left;
    padding: 0 2em;
  }
`;

const MeldKjop = styled.div`
  padding: 2em 3em;
  width: 100%;
  background-color: #000;
  clear: both;
`;

const Button = styled.a`
  width: 200px;
  display: inline-block;
  background-color: #222;
  padding: 10px;
  margin: 0 10px 10px;
`;
